import React, { Component } from 'react';
import PageTitle from '../panels/PageTitle';
import IconBadge from '../badges/IconBadge';
import style from './ReportPage.module.scss';
import Section from '../panels/Section';
import ReportPanel from './ReportPanel';

class ReportPage extends Component {
  getReport(endpoint, title, fields = [], extraParams = '') {
    return (
      <ReportPanel
        endpoint={endpoint}
        title={title}
        user={this.props.user}
        fields={fields || []}
        extraParams={extraParams}
      />
    );
  }

  getUserReportPanel = () => (
    <div className={style.panelsContainer}>
      {this.getReport('Resumo_por_Agenda', 'Contato por agenda')}
    </div>
  );

  getManagerReportPanel = () => {
    const Cd_Loja = this.props.user.Loja;

    return (
      <div className={style.panelsContainer}>
        {this.getReport('Totais_Por_Agenda', 'Totais por agenda', Cd_Loja ? ['date'] : ['stores', 'date'])}
        {this.getReport('Totais_Por_Loja', 'Totais por loja', Cd_Loja ? ['date'] : ['stores', 'date'])}
        {this.getReport('Resumo_Vendas_Meta', 'Totais de vendas e metas', Cd_Loja ? [] : ['stores'])}
        {this.getReport('Resumo_Por_Vendedor', 'Contatos por vendedor', Cd_Loja ? ['date'] : ['stores', 'date'])}
        {this.getReport('Resumo_Operacao', 'Operação', Cd_Loja ? ['SEMUSER'] : ['stores', 'SEMUSER'])}
        {this.getReport('Ranking_Por_Vendedor', 'Ranking por Vendedor', ['stores'] )}
      </div>
    );
  };

  renderReportPanels() {
    switch (this.props.user.Perfil) {
      case 1:
        return this.getUserReportPanel();

      case 2:
      case 3:
      default:
        return this.getManagerReportPanel();
    }
  }

  render() {
    return (
      <React.Fragment>
        <PageTitle>
          <div className={style.title}>
            <IconBadge />
            Relatórios
          </div>
        </PageTitle>

        <div className={style.container}>
          <Section
            title="Relatórios"
            expandable={false}
            content={this.renderReportPanels()}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ReportPage;
